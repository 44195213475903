<template>
    <div class="sld_casual_question">
        <div class="top_header">
            <p class="header_title">{{L['常见问题']}}</p>
            <button @click="openAdd">{{L['添加常见问题']}}</button>
        </div>
        <!-- <div class="top_bar">
            <div class="number_title">序号</div>
            <div class="reply_title">问题</div>
            <div class="answer_title">回答</div>
            <div class="sort_title">排序</div>
            <div class="default_use_title">显示</div>
            <div class="operate_title">操作</div>
        </div>
        <div class="container" v-if="quesList&&quesList.list&&quesList.list.length>0">
            <el-scrollbar>
                <div class="con_item" v-for="(quesItem,quesIndex) in quesList.list" :key="quesIndex">
                    <div class="number">{{quesIndex+1}}</div>
                    <div class="content">{{quesItem.msgContent}}</div>
                    <div class="answer">{{quesItem.msgReply}}</div>
                    <div class="sort">{{quesItem.sort}}</div>
                    <div class="default_use">
                        <div class="switch_wrap" @click="operateContent(1,quesItem)">
                            <el-switch v-model="quesItem.isShow"></el-switch>
                        </div>
                    </div>
                    <div class="operate">
                        <button @click="openEdit(quesItem)">编辑</button>
                        <el-popconfirm title="这是一段内容确定删除吗？" @confirm="operateContent(0,quesItem)" confirmButtonText='确定'
                            cancelButtonText='取消'>
                            <template #reference>
                                <button>删除</button>
                            </template>
                        </el-popconfirm>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="empty_ques" v-if="(!quesList.list.length>0)&&firstLoading">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>暂无常见问题~</p>
        </div> -->


        <el-table :data="quesList.list" :row-class-name="tableRowClassName">
            <el-table-column type="index" width="120" :label="L['序号']">
            </el-table-column>
            <el-table-column prop="msgContent" :label="L['问题']" width="480">
            </el-table-column>
            <el-table-column prop="msgReply" :label="L['回答']" width="480">
            </el-table-column>
            <el-table-column prop="sort" :label="L['排序']" width="150">
            </el-table-column>
            <el-table-column :label="L['显示']" width="190">
                <template #default="scope">
                    <div class="switch_wrap" @click="operateContent(1,scope.row)">
                        <el-switch v-model="scope.row.isShow"></el-switch>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="L['操作']" width="300">
                <template #default="scope">
                    <button @click="openEdit(scope.row)">{{L['编辑']}}</button>
                    <el-popconfirm :title="L['确定删除此内容吗？']" @confirm="operateContent(0,scope.row)"
                        :confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
                        <template #reference>
                            <button>{{L['删除']}}</button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div class="empty_ques" v-if="(!quesList.list.length>0)&&firstLoading">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{L['暂无常见问题~']}}</p>
        </div>





        <el-dialog :title="dialogTitle" v-model="dialogVisible" width="30%" lock-scroll="false" top="21vh"
            @closed="dialogClosed">
            <table id="popTable">
                <tr class="row1">
                    <td class="title" valign="top"><i>*</i><span>{{L['常见问题']}}：</span></td>
                    <td>
                        <el-input type="textarea" :rows="4" :placeholder="L['请输入常见问题']" v-model="quesContent"
                            resize="none" maxlength="50" show-word-limit @input="watchContent(0)">
                        </el-input>
                        <span v-show="contentMessage" class="message">{{contentMessage}}</span>
                    </td>
                </tr>
                <tr class="row2">
                    <td></td>
                    <td class="tip"><span>{{L['最多输入50字']}}</span></td>
                </tr>
                <tr class="row3">
                    <td valign="top"><span><i>*</i>{{L['解决回答']}}：</span></td>
                    <td>
                        <el-input type="textarea" :rows="6" :placeholder="L['请输入解决回答']" v-model="replyAnswer"
                            resize="none" maxlength="100" show-word-limit @input="watchContent(1)">
                        </el-input>
                        <span v-show="ansMessage" class="message">{{ansMessage}}</span>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td class="tip">{{L['最多输入100字']}}</td>
                </tr>
                <tr class="row4">
                    <td class="title" valign="top"><i>*</i><span>{{L['输入排序']}}：</span></td>
                    <td>
                        <el-input :placeholder="L['请输入排序']" v-model="replySort" @input="watchContent(2)"></el-input>
                        <span v-show="sortMessage" class="message">{{ sortMessage }}</span>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td class="tip"><span>{{L['请输入0~255的数字，数值越小，显示越靠前']}}</span></td>
                </tr>
                <tr class="row5">
                    <td style="text-align: right;"><span>{{L['显示']}}：</span></td>
                    <td>
                        <el-switch v-model="switchFlag"></el-switch>
                    </td>
                </tr>

            </table>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">{{L['取 消']}}</el-button>
                    <el-button type="primary" @click="confirm">{{L['确 定']}}</el-button>
                </span>
            </template>
        </el-dialog>

        <div class="sld_pagination">
            <el-pagination @prev-click="handlePrevCilickChange" @next-click="handleNextCilickChange"
                @current-change="handleCurrentChange" :currentPage="page.current" :page-size="page.pageSize"
                layout="prev, pager, next, jumper" :total="page.total" :hide-on-single-page="true">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { ref, getCurrentInstance, reactive, onMounted } from 'vue'
    import { ElMessage } from 'element-plus'
    export default {
        name: 'autoReply',
        setup() {
            const msgId = ref('')//全局问题ID
            const addOrEdit = ref(0)
            const quesContent = ref('')
            const replySort = ref('')
            const dialogVisible = ref(false)
            const page = reactive({//页数
                current: '',
                pageSize: '',
                total: ''
            })
            const replyAnswer = ref('')//回答内容
            const dialogTitle = ref('')
            const quesList = reactive({ list: [] })//常见问题列表
            const params = reactive({ current: 1 })
            const switchFlag = ref(false)
            const { proxy } = getCurrentInstance()
            const contentMessage = ref('')
            const sortMessage = ref('')
            const ansMessage = ref('')
            const firstLoading = ref(false)
            const L = proxy.$getCurLanguage()
            const isPreventClick = ref(false)
            const identity = localStorage.getItem('identity')

            //获取常见问题
            const getQuesList = () => {
                proxy.$get(`v3/helpdesk/${identity}/problem/list`, params).then(res => {
                    if (res.state == 200) {
                        quesList.list = res.data.list
                        page.pageSize = res.data.pagination.pageSize
                        page.total = res.data.pagination.total
                        page.current = res.data.pagination.current
                        quesList.list.map(item => {
                            item.isShow = item.isShow ? true : false
                        })
                        firstLoading.value = true
                    }
                })
            }

            //页数改变方法--start
            const handlePrevCilickChange = (e) => {
                params.current = e
                getQuesList()
            }

            const handleNextCilickChange = (e) => {
                params.current = e
                getQuesList()
            }

            const handleCurrentChange = (e) => {
                params.current = e
                getQuesList()
            }
            //页数改变方法--end

            //确认start
            const confirm = () => {


                if (isPreventClick.value) {
                    return
                }
                isPreventClick.value = true


                let num = /^[0-9]+[0-9]*]*$/
                if (!quesContent.value) {
                    contentMessage.value = L['不能为空']
                    isPreventClick.value = false
                    return
                } else if (!replyAnswer.value) {
                    ansMessage.value = L["回答不能为空"]
                    isPreventClick.value = false
                    return
                } else if (Number(replySort.value) > 255 || Number(replySort.value) < 0 || !num.test(replySort.value)) {
                    sortMessage.value = L["值应为0~255的整数值"]
                    isPreventClick.value = false
                    return
                } else if (!replySort.value.toString()) {
                    sortMessage.value = L["排序值不能为空"]
                    isPreventClick.value = false
                    return
                }

                let isShow = switchFlag.value ? 1 : 0

                if (addOrEdit.value == 0) {
                    let params = {
                        msgContent: quesContent.value,
                        sort: replySort.value,
                        isShow: isShow,
                        msgReply: replyAnswer.value
                    }
                    proxy.$post(`v3/helpdesk/${identity}/problem/add`, params).then(res => {
                        if (res.state == 200) {
                            ElMessage.success(res.msg)
                            dialogVisible.value = false

                            getQuesList()
                        } else {
                            ElMessage(res.msg)
                            isPreventClick.value = false

                        }
                    })

                } else if (addOrEdit.value == 1) {
                    let params = {
                        problemMsgId: msgId.value,
                        isShow: isShow,
                        msgContent: quesContent.value,
                        sort: replySort.value,
                        msgReply: replyAnswer.value
                    }
                    proxy.$post(`v3/helpdesk/${identity}/problem/update`, params).then(res => {
                        if (res.state == 200) {
                            ElMessage.success(res.msg)
                            dialogVisible.value = false

                            getQuesList()
                        } else {
                            ElMessage(res.msg)
                            isPreventClick.value = false

                        }
                    })
                }


            }
            //确认end

            const watchContent = (type) => {
                if (type == 0 && quesContent.value) {
                    contentMessage.value = ""
                } else if (type == 2 && replySort.value && replySort.value < 255) {
                    sortMessage.value = ""
                } else if (type == 1 && replyAnswer.value) {
                    ansMessage.value = ""
                }
            }




            //改变开关或者删除start
            const operateContent = (type, quesItem) => {
                quesItem.isShow = !quesItem.isShow
                let isShowFlag = !quesItem.isShow ? 1 : 0

                let params = {
                    problemMsgId: quesItem.problemMsgId,
                }
                if (type == 1) {
                    params.isShow = isShowFlag
                    proxy.$post(`v3/helpdesk/${identity}/problem/isShow`, params).then(res => {
                        if (res.state == 200) {
                            ElMessage.success(res.msg)
                            getQuesList()
                        } else {
                            ElMessage(res.msg)
                        }
                    })
                } else if (type == 0) {
                    proxy.$post(`v3/helpdesk/${identity}/problem/delete`, params).then(res => {
                        if (res.state == 200) {
                            ElMessage.success(res.msg)
                            getQuesList()
                        } else {
                            ElMessage(res.msg)
                        }
                    })
                }

            }
            //改变开关或者删除end

            //打开对话框（编辑内容）
            const openEdit = (item) => {
                dialogTitle.value = L["编辑常见问题"]
                addOrEdit.value = 1
                dialogVisible.value = true
                msgId.value = item.problemMsgId
                quesContent.value = item.msgContent
                replySort.value = item.sort
                switchFlag.value = item.isShow
                replyAnswer.value = item.msgReply
                isPreventClick.value = false
            }

            //打开对话框（添加内容）
            const openAdd = () => {
                dialogTitle.value = L["添加常见问题"]
                quesContent.value = ''
                replySort.value = ''
                switchFlag.value = false
                replyAnswer.value = ''
                addOrEdit.value = 0
                dialogVisible.value = true
                isPreventClick.value = false
            }

            const dialogClosed = () => {
                isPreventClick.value = false

            }

            onMounted(() => {
                getQuesList()
            })


            return {
                dialogVisible,
                quesContent,
                replySort,
                quesList,
                page,
                handlePrevCilickChange,
                handleNextCilickChange,
                handleCurrentChange,
                switchFlag,
                confirm,
                operateContent,
                openEdit,
                openAdd,
                dialogTitle,
                replyAnswer,
                contentMessage,
                sortMessage,
                ansMessage,
                watchContent,
                firstLoading,
                L,
                dialogClosed
            }
        }
    }
</script>

<style lang="scss">
    .el-table th>.cell {
        text-align: center;
    }

    .el-table .cell {
        display: flex;
        justify-content: center;
    }

    .el-table__empty-block {
        display: none;
    }

    button {
        padding: 4px 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 13px;
        margin-left: 10px;
        border-radius: 4px;
        background: #FFF;
        border: 1px solid #DCDFE6;
        color: #606266;
        white-space: nowrap;

        &:hover {
            border: 1px solid $colorMain;
            color: $colorMain;
        }
    }


    .sld_casual_question {
        .top_header {
            width: 100%;
            height: 59px;
            border-bottom: 1px solid #F6F6F6;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .header_title {
                padding-left: 10px;
                border-left: 4px solid $colorMain;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
            }

            button {
                color: #fff;
                padding: 11px;
                background-color: $colorMain;
                border-radius: 3px;
                border: none;
                margin-right: 15px;
                font-size: 14px;
                font-family: Microsoft YaHei;
            }
        }

        .top_bar {
            padding: 13px 0;
            background-color: #eee;
            display: flex;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            flex-shrink: 0;

            .number_title {
                padding: 0 80px;
            }

            .reply_title {
                width: 340px;
                text-align: center;
                /* padding: 0 180px; */
            }

            .sort_title {
                width: 200px;
                text-align: center;
                /* padding: 0 100px; */
            }

            .default_use_title {
                width: 270px;
                text-align: center;
                padding: 0 120px;
            }

            .operate_title {
                width: 310px;
                text-align: center;
                /* padding: 0 140px; */
            }

            .answer_title {
                width: 234px;
                text-align: center;
                /* padding: 0 100px; */
            }
        }

        .container {
            height: 710px;

            .con_item {
                width: 99%;
                display: flex;
                height: 50px;
                align-items: center;
                border-bottom: 1px solid #f2f2f2;

                .number {
                    width: 190px;
                    text-align: center;
                }

                .sort {
                    text-align: center;
                    width: 200px;
                }

                .default_use {
                    padding: 0 117px;
                }

                .operate {
                    width: 310px;
                    display: flex;
                    justify-content: center;


                }

                .content {
                    width: 340px;
                    text-align: center;
                    word-break: break-all;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                .answer {
                    width: 240px;
                    text-align: center;
                    word-break: break-all;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    padding-left: 10px;
                    padding-right: 10px;
                }

            }
        }

        .sld_pagination {
            margin-top: 20px;
        }

        .el-dialog {
            border-radius: 10px;
        }

        .el-dialog__header {
            background: #409Eff;
            border-top-right-radius: 9px;
            border-top-left-radius: 9px;
            height: 40px;
            padding: 11px 20px 10px;
        }

        .el-dialog__headerbtn {
            top: 13px;
        }

        .el-dialog__title {
            color: #fff;
        }

        .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
        }

        .el-button {
            padding: 7px 19px;
        }

        #popTable {
            margin: 0 auto;
            border-spacing: 10px;

            .title {
                text-align: right;
            }

            .tip {
                color: #C0C4CC;
            }

            i {
                color: #e2231a;
                font-style: normal;
            }

            .message {
                font-size: 12px;
                line-height: 2;
                padding-top: 4px;
                color: #e2231a;
                font-family: Microsoft YaHei;
            }

            td {
                max-width: 284px;

                &.title {
                    padding-top: 9px;
                }
            }
        }
    }

    .empty_ques {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 200px;

        p {
            color: #999999;
            font-size: 14px;
            margin-left: 10px;
        }
    }
</style>